<template>
    <a @click.prevent="cargarExpediente(trabajo.id_servicio,trabajo)" href="#" class="dropdown-item"  v-for="trabajo in trabajos" :key="trabajo.id_aviso" >
        <div data-toggle="tooltip" data-placement="top" v-tooltip="trabajo.direccion_cliente" style="display:flex; justify-content:space-between; overflow-x:auto">
            <span style="width:100%"><i :class="trabajo.class" :style="trabajo.style" :title="trabajo.tipo"></i> {{trabajo.codigo_servicio}}</span>
            <span style="width:100%; text-align:center">{{ trabajo.nombre_tramitador }}</span>
            <span style="width:100%; text-align:center" class="float-right text-muted text-sm">{{trabajo.visita_fecha_hora}}</span>
            <span style="width:100%; text-align:end" class="float-right text-muted text-sm"> {{ trabajo.nombre_especialidad }}</span>
        </div>
    </a>
</template>
<script>

export default({
    props:{
        trabajos: Array
    },
    setup() {
        
    },
    methods: {
        cargarExpediente(id,trabajo){
            localStorage.setItem("tiposerv", 'norealizados');
            localStorage.setItem('nombreservicio' + id, trabajo.codigo_servicio);

            this.$router.push({
                name: 'Servicio',
                params: {
                    id:id
                }
            });
        },
        extractContent(s) {
            var span = document.createElement('span');
            s

            span.innerHTML = s;
            try{
                return decodeURIComponent(escape(span.textContent)).replace(/<[^>]*>/g, '') || decodeURIComponent(escape(span.innerText)).replace(/<[^>]*>/g, '');
            }
            catch (error) {
                console.log(error);
                return s.replace(/<[^>]*>/g, '');

            }
        },
    }
})
</script>
